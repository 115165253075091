import React, { useState, useEffect } from 'react';
import Menu from './Component/Menu';

import WhitePricingTable from "./White/WhitePricingTable";
import BlackPricingTable from "./Black/BlackPricingTable";
import GlassPricingTable from "./Glass/GlassPricingTable";

function App() {
  const [activeComponent, setActiveComponent] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState('#fff'); // Default background color


  const handleMenuItemClick = (componentName) => {
    setActiveComponent(componentName);

    // Change the background color based on the selected component
    if (componentName === 'Component1') {
      setBackgroundColor('#fff'); 
    } else if (componentName === 'Component2') {
      setBackgroundColor('#212121');
    } else if (componentName === 'Component3') {
      setBackgroundColor('#212121');
    }
  };

  // Apply the background color to the body using useEffect
  useEffect(() => {
    document.body.style.backgroundColor = backgroundColor;
    return () => {
      // Clean up background color when the component unmounts (optional)
      document.body.style.backgroundColor = null;
    };
  }, [backgroundColor]);

  return (
    <div className="App">
      <Menu onMenuItemClick={handleMenuItemClick} />
      {activeComponent === 'Component1' ? <WhitePricingTable />
        : activeComponent === 'Component2' ? <BlackPricingTable />
          : activeComponent === 'Component3' ? <GlassPricingTable />
            : <WhitePricingTable />}
    </div>
  );
}

export default App;
