import React, { useState, useEffect } from 'react';
import './BlackStyle.css';

const PricingCard = ({ plan, billingCycle }) => {

    const getPrice = () => {
        switch (billingCycle) {
            case 'Quarterly':
                return (plan.price * 3 * 0.9).toFixed(2);
            case 'Halfyearly':
                return (plan.price * 6 * 0.85).toFixed(2);
            case 'Yearly':
                return (plan.price * 12 * 0.8).toFixed(2);
            default:
                return plan.price.toFixed(2);
        }
    };

    return (
        <div className="black-pricing-card">
            <h2>{plan.ram}</h2>
            <p>{plan.description}</p>
            <h3>${getPrice()}/mo</h3>
            <button className="black-order-now">Order Now</button>
            <div className="black-card-details">
                <p><i className="icon-location" /> Location: {plan.location}</p>
                <p><i className="icon-cpu" /> CPU: {plan.cpu}</p>
                <p><i className="icon-disk" /> Disk: {plan.disk}</p>
                <p><i className="icon-data" /> Data: {plan.data}</p>
                <p><i className="icon-ram" /> RAM: {plan.memory}</p>
            </div>
        </div>
    );
};

const PricingTable = () => {
    const [billingCycle, setBillingCycle] = useState('Monthly');
    const [selectedCountry, setSelectedCountry] = useState('USA');
    const [countries, setCountries] = useState([]);
    const [plans, setPlans] = useState([]);

    useEffect(() => {
        fetch('/countries.json')  // Ensure the path is correct
            .then(response => response.json())
            .then(data => {
                setCountries(data);
                // Set initial plans based on the default selected country (USA)
                const initialCountry = data.find(country => country.name === 'USA');
                if (initialCountry) setPlans(initialCountry.plans);
            })
            .catch(error => console.error('Error fetching countries:', error));
    }, []);

    const handleBillingCycleChange = (cycle) => {
        setBillingCycle(cycle);
    };

    const handleCountryChange = (countryName) => {
        setSelectedCountry(countryName);
        const selectedCountryData = countries.find(country => country.name === countryName);
        if (selectedCountryData) {
            setPlans(selectedCountryData.plans);
        }
    };

    return (
        <div className="black-pricing-table">
            <div className="black-country-selection">
                {countries.length === 0 ? (
                    <p>Loading countries...</p>
                ) : (
                    countries.map(country => (
                        <button
                            key={country.name}
                            className={selectedCountry === country.name ? 'active' : ''}
                            onClick={() => handleCountryChange(country.name)}
                        >
                            {country.name}
                        </button>
                    ))
                )}
            </div>

            <div className="black-billing-cycle">
                {['Monthly', 'Quarterly (10% Off)', 'Halfyearly (15% Off)', 'Yearly (20% Off)'].map(cycle => (
                    <button
                        key={cycle}
                        className={billingCycle === cycle.split(' ')[0] ? 'active' : ''}
                        onClick={() => handleBillingCycleChange(cycle.split(' ')[0])}
                    >
                        {cycle}
                    </button>
                ))}
            </div>

            <div className="black-pricing-cards-container">
                {plans.length > 0 ? (
                    plans.map((plan, index) => (
                        <PricingCard key={index} plan={plan} billingCycle={billingCycle} />
                    ))
                ) : (
                    <p>No plans available for the selected country.</p>
                )}
            </div>
        </div>
    );
};

export default PricingTable;
