import React from 'react';
import "./Menu.css";

const Menu = ({ onMenuItemClick }) => {
    return (
        <div className="menu">
            <button onClick={() => onMenuItemClick('Component1')} className="menu-button">White</button>
            <button onClick={() => onMenuItemClick('Component2')} className="menu-button">Black</button>
            <button onClick={() => onMenuItemClick('Component3')} className="menu-button">Glass</button>
        </div>
    );
};

export default Menu;