import React, { useState, useEffect } from 'react';
import './GlassStyle.css'; // Import CSS

const PricingCard = ({ plan, billingCycle }) => {

    const getPrice = () => {
        switch (billingCycle) {
            case 'Quarterly':
                return (plan.price * 3 * 0.9).toFixed(2);
            case 'Halfyearly':
                return (plan.price * 6 * 0.85).toFixed(2);
            case 'Yearly':
                return (plan.price * 12 * 0.8).toFixed(2);
            default:
                return plan.price.toFixed(2);
        }
    };

    return (

        <div className="cards__card card">
            <h2 className="card__heading">{plan.description}</h2>
            <p className="card__price">${getPrice()}/mo</p>
            <ul className="card__bullets flow">
                <li>Location: {plan.location}</li>
                <li>CPU: {plan.cpu}</li>
                <li>Disk: {plan.disk}</li>
                <li>Data: {plan.data}</li>
                <li>RAM: {plan.memory}</li>
            </ul>
            <a href="#basic" className="card__cta cta">Order Now</a>
        </div>
    );
};

const Pricing = () => {

    const [billingCycle, setBillingCycle] = useState('Monthly');
    const [selectedCountry, setSelectedCountry] = useState('USA');
    const [countries, setCountries] = useState([]);
    const [plans, setPlans] = useState([]);

    useEffect(() => {
        fetch('/countries.json')  // Ensure the path is correct
            .then(response => response.json())
            .then(data => {
                setCountries(data);
                // Set initial plans based on the default selected country (USA)
                const initialCountry = data.find(country => country.name === 'USA');
                if (initialCountry) setPlans(initialCountry.plans);
            })
            .catch(error => console.error('Error fetching countries:', error));
    }, []);

    const handleBillingCycleChange = (cycle) => {
        setBillingCycle(cycle);
    };

    const handleCountryChange = (countryName) => {
        setSelectedCountry(countryName);
        const selectedCountryData = countries.find(country => country.name === countryName);
        if (selectedCountryData) {
            setPlans(selectedCountryData.plans);
        }
    };

    useEffect(() => {
        const cardsContainer = document.querySelector(".cards");
        const overlay = document.querySelector(".overlay");
        const cards = Array.from(document.querySelectorAll(".card"));

        const applyOverlayMask = (e) => {
            const x = e.pageX - cardsContainer.offsetLeft;
            const y = e.pageY - cardsContainer.offsetTop;
            overlay.style = `--opacity: 1; --x: ${x}px; --y:${y}px;`;
        };


        const observer = new ResizeObserver((entries) => {
            entries.forEach((entry) => {
                const cardIndex = cards.indexOf(entry.target);

                let width = entry.borderBoxSize ? entry.borderBoxSize[0].inlineSize : entry.contentRect.width;
                let height = entry.borderBoxSize ? entry.borderBoxSize[0].blockSize : entry.contentRect.height;

                if (cardIndex >= 0) {
                    requestAnimationFrame(() => {
                        overlay.children[cardIndex].style.width = `${width}px`;
                        overlay.children[cardIndex].style.height = `${height}px`;
                    });
                }
            });
        });

        const initOverlayCard = (cardEl) => {
            const overlayCard = document.createElement("div");
            overlayCard.classList.add("card");

            overlay.append(overlayCard);
            observer.observe(cardEl);
        };


        cards.forEach(initOverlayCard);
        document.body.addEventListener("pointermove", applyOverlayMask);

        return () => {
            document.body.removeEventListener("pointermove", applyOverlayMask);
        };
    }, []);

    return (
        <div className="glass-pricing-table">
            <main className="main flow">

                <div className="glass-country-selection">
                    {countries.length === 0 ? (
                        <p>Loading countries...</p>
                    ) : (
                        countries.map(country => (
                            <button
                                key={country.name}
                                className={selectedCountry === country.name ? 'active' : ''}
                                onClick={() => handleCountryChange(country.name)}
                            >
                                {country.name}
                            </button>
                        ))
                    )}
                </div>

                <div className="glass-billing-cycle">
                    {['Monthly', 'Quarterly (10% Off)', 'Halfyearly (15% Off)', 'Yearly (20% Off)'].map(cycle => (
                        <button
                            key={cycle}
                            className={billingCycle === cycle.split(' ')[0] ? 'active' : ''}
                            onClick={() => handleBillingCycleChange(cycle.split(' ')[0])}
                        >
                            {cycle}
                        </button>
                    ))}
                </div>

                <div className="main__cards cards">
                    <div className="cards__card card">
                        <div className="cards__inner">

                            {plans.length > 0 ? (
                                plans.map((plan, index) => (
                                    <PricingCard key={index} plan={plan} billingCycle={billingCycle} />
                                ))
                            ) : (
                                <p>No plans available for the selected country.</p>
                            )}

                        </div>
                        <div className="overlay cards__inner"></div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Pricing;
